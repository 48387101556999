import Swiper from './../libs/swiper.min.js';

document.addEventListener('DOMContentLoaded', function () {
    const sliders = document.querySelectorAll('.news-block');
    sliders?.forEach(slider => {
        const slides = slider.querySelectorAll('.wp-block-post');

        slides.forEach(slide => slide.classList.add('swiper-slide'))
    });

    sliders?.forEach(slider => {
        let swiper = new Swiper(".swiper", {
            mousewheel: true,
            keyboard: true,
            autoplay: true,
            speed: 700,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            swiperOptions: {
                slideClass: 'wp-block-post'
            }
        });
    });
});